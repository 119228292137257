<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px;line-height: 40px">
        <el-col :span="24">
          <el-input ref="assetSn" v-model="assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="search" style="width: 250px"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
          <el-button type="primary" @click="submit('outer')" :loading="submitLoading">打印外码</el-button>
          <el-button type="primary" @click="submit('inner')" :loading="submitLoading">打印内码</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>
      <MaterialInfo :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="pack"/>
    </el-card>
  </div>
</template>

<script>
import MaterialInfo from "@/views/material/MaterialInfo";
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "AssetSnPrint",
  components: {MaterialInfo, MessageComponent},
  data() {
    return {
      assetSn: '',
      busItem: {},
      lendOrder: {},
      lendOrderItem: {},
      loading: false,
      submitLoading: false,
    }
  },
  methods: {
    clearData() {
      this.assetSn = ''
      this.busItem = {}
      this.lendOrder = {}
      this.lendOrderItem = {}
    },
    async search() {
      this.$refs.searchMessage.clear();
      if (!this.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }

      this.loading = true;
      const {data: res} = await this.$axios.get('/busItem/queryInfoForDetail', {
        params: {assetSn: this.assetSn}
      });
      this.loading = false;

      if (res.code !== 0) {
        this.$refs.searchMessage.showError(res.message);
        return
      }

      if (!res.data.busItem) {
        this.busItem = {};
        this.lendOrder = {};
        this.lendOrderItem = {};
        return this.$refs.searchMessage.showError('资产码不正确');
      }

      this.busItem = res.data.busItem || {};
      this.lendOrder = res.data.lendOrder || {};
      this.lendOrderItem = res.data.lendOrderItem || {};
    },
    async submit(type) {
      if (!this.assetSn) {
        return this.$refs.searchMessage.showError('请输入或扫资产码');
      }
      if (!this.busItem.id) {
        return this.$refs.searchMessage.showError('请先查询资产信息');
      }
      if (this.$socket.notOk()) {
        return this.$refs.searchMessage.showError('未成功连接打印机小程序');
      }

      this.submitLoading = true;
      const {data: res} = await this.$axios.post('/busItem/printAssetSn', {
        'assetSn': this.assetSn,
        'type': type
      });
      this.submitLoading = false;
      if (res.code !== 0) {
        return this.$refs.searchMessage.showError(res.message);
      }

      let assetSnBO = res.data.assetSnBO;
      if (!assetSnBO) {
        this.$refs.searchMessage.showSuccess('未返回打印数据');
      }

      this.$socket.send({
        "requestId": Math.round(Math.random() * 100000000),
        "printType": "asset",
        "printer": process.env.VUE_APP_ASSET_SN_PRINTER_NAME,
        "printVO": assetSnBO
      })

      this.$refs.searchMessage.showSuccess('打印指令已发送');

      //清掉之前的资产码
      this.clearData();
      this.$refs.assetSn.focus();
    },
  }
}
</script>

<style scoped>

</style>